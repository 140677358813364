<template>
  <div class="home">
    <eden-page-header
      v-if="!generalAccess"
      :title="'Gardeners'"
      :subtitle="gardener.name"
      :section="'Payment Tracker'"
    >
    </eden-page-header>
    <eden-page-header v-else :title="'Gardeners'" :subtitle="'Payment Tracker'">
    </eden-page-header>
    <div class="is-flex is-justify-center">
      <eden-periods
        :key="tab"
        :orientation="'horizontal'"
        :default-period="'thisweek'"
        :custom-periods="periods"
        :show-custom-period="true"
        @set-period="getSummary"
      />
    </div>
    <payment-tracker-stat
      :loading="loading"
      :received-amount="amount.received"
      :total-amount="amount.total"
      :paid-customers="customers.paid"
      :total-customers="customers.total"
    />
    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane label="Individual Customers" name="individual">
        <customers-individual-tracker :gardener-id="gardener.id" />
      </el-tab-pane>
      <el-tab-pane label="Corporate Customers" name="corporate">
        <customers-corporate-tracker :gardener-id="gardener.id" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PaymentTrackerStat from "@/components/Gardeners/PaymentTracker/PaymentTrackerStat";
import CustomersIndividualTracker from "@/components/Gardeners/PaymentTracker/Individual/CustomersIndividualTracker.vue";
import CustomersCorporateTracker from "@/components/Gardeners/PaymentTracker/Corporate/CustomersCorporateTracker.vue";
import payment from "@/requests/gardeners/payment-tracker";
import * as mutations from "@/store/mutation-types";

export default {
  name: "PaymentTracker",
  components: {
    PaymentTrackerStat,
    CustomersIndividualTracker,
    CustomersCorporateTracker,
  },
  data() {
    return {
      period: "thisweek",
      periods: {
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
        custom: "Custom",
      },
      tab: "individual",
      generalAccess: true,
      gardener: {
        id: null,
        name: "-",
      },
      summary: {},
      loading: true,
    };
  },
  computed: {
    amount() {
      return {
        received: this.summary.total_amount_paid ?? 0,
        total: this.summary.total_amount_expected ?? 0,
      };
    },
    customers() {
      return {
        paid: this.summary.total_customers_that_have_paid ?? 0,
        total: this.summary.total_customers_expected_to_pay ?? 0,
      };
    },
  },
  watch: {
    tab() {
      this.getSummary({ period: "thisweek" });
    },
  },
  created() {
    this.loading = true;
    let { tab } = this.$route.query;
    this.tab = tab ? tab : "individual";
    if (this.$route.params.id) {
      this.gardener.id = this.$route.params.id;
    }
    this.getSummary({ period: this.period });
  },
  methods: {
    getSummary({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      let payload = {
        customer_type: this.tab,
        start_date,
        end_date,
      };

      if (this.gardener.id) {
        payload.gardener_id = this.gardener.id;
      }
      payment
        .summary(payload)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary = data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "gardeners.payment-tracker",
        params: { id: this.$route.params.id },
        query: { tab: tab.name },
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
    setTimeout(() => {
      this.$store.commit(mutations.RESET_GARDENERS_PAYMENT_TRACKER);
    }, 500);
  },
};
</script>
