<template>
  <div class="individual-customers">
    <eden-table-actions :title="title" :show-search="false" @search="search">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getPaymentActivity"
        />
        <payment-tracker-filter
          :class="'mx-10'"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          :disabled="!allowExport"
          @click="showCustomersExport = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <eden-loader v-if="loading" />
      <template v-else>
        <el-table :data="pageData">
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Name </span>
            </template>
            <template v-slot="scope">
              <span class="text-primary">
                {{ formatName(scope.row.name) }}</span
              >
              <span class="is-block font-xsm">{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <span class="eden-table-header">Gardener </span>
            </template>
            <template v-slot="scope">
              <span
                :class="['font-sm', { 'text-danger': !scope.row.gardener }]"
                >{{ scope.row.gardener || "No assigned gardener" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <span class="eden-table-header">Status</span>
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                <el-tag :type="setType(scope.row.status)">
                  {{ formatText(scope.row.status) }}
                </el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Services</span>
            </template>
            <template v-slot="scope">
              <eden-services-list
                v-if="scope.row.services"
                :services="scope.row.services"
              />
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Expected Amount</span>
            </template>
            <template v-slot="scope">
              <span>{{ formatPrice(scope.row.subscription_amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Billing date</span>
            </template>
            <template v-slot="scope">
              <span>{{
                scope.row.billing_date
                  ? formatDate(scope.row.billing_date, "m do, y")
                  : `-`
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <span class="eden-table-header">Payment Status</span>
            </template>
            <template v-slot="scope">
              <el-tag :type="setType(scope.row.payment_status)">
                {{ formatText(scope.row.payment_status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <span class="eden-table-header">Reason</span>
            </template>
            <template v-slot="scope">
              <span>{{ formatText(scope.row.reason) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Amount Paid</span>
            </template>
            <template v-slot="scope">
              <span>{{ formatPrice(scope.row.amount_paid) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span class="eden-table-header">Payment Date</span>
            </template>
            <template v-slot="scope">
              <span>{{
                scope.row.payment_date
                  ? formatDate(scope.row.payment_date, "m do, y")
                  : `-`
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <span>Conversion</span>
            </template>
            <template v-slot="scope">
              <div
                v-if="scope.row.conversion.length"
                class="is-flex is-align-center"
              >
                <el-tag
                  v-for="(type, index) in scope.row.conversion"
                  :key="index"
                  :type="setType(type)"
                >
                  {{ formatText(type) }}
                </el-tag>
              </div>
              <el-tag v-else type="info"> Not Applicable </el-tag>
            </template>
          </el-table-column>
          <el-table-column>
            <template v-slot="scope">
              <payment-tracker-actions
                :customer="scope.row"
                :index="scope.$index"
                :account="'individual'"
                @update="getPaymentActivity({ period: period })"
                @updateTracker="updateTracker"
              />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="pageData.length && showPagination"
          :from="pagination.from"
          :to="pagination.to"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :current-page.sync="page"
        />
      </template>
    </template>
    <payment-tracker-export
      :show.sync="showCustomersExport"
      :type="'individual'"
      :data-to-export="pageData"
    />
  </div>
</template>

<script>
import PaymentTrackerFilter from "@/components/Gardeners/PaymentTracker/PaymentTrackerFilter";
import PaymentTrackerExport from "@/components/Gardeners/PaymentTracker/PaymentTrackerExport";
import PaymentTrackerActions from "@/components/Gardeners/PaymentTracker/Actions/PaymentTrackerActions";

import * as actions from "@/store/action-types";
import payment from "@/requests/gardeners/payment-tracker";

import { formatIndividualCustomers } from "../format-data";

export default {
  name: "CustomersIndividualTracker",
  props: {
    gardenerId: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    PaymentTrackerExport,
    PaymentTrackerFilter,
    PaymentTrackerActions,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      period: "thisweek",
      custom: {
        from: null,
        to: null,
      },
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
      },
      showPagination: true,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: {
        from: "",
        to: "",
        total: "",
        perPage: "",
      },
      showCustomersExport: false,
      gardener: {
        customers: [],
      },
    };
  },
  computed: {
    customers() {
      return this.$store.getters.gardeners_payment_tracker.individual;
    },
    perPage() {
      return this.customers.per_page;
    },
    title() {
      const total = this.pagination.total;
      const result = this.pageData.length;
      return `${
        this.showPagination ? total || 0 : result
      } Individual Customers`;
    },
    allowExport() {
      return this.pageData.length && !this.loading;
    },
  },
  watch: {
    page() {
      if (!this.filterParams.status) {
        this.getPaymentActivity({
          period: this.period,
          from: this.custom.from,
          to: this.custom.to,
        });
      } else {
        this.filter(this.filterParams);
      }
    },
  },
  created() {
    const pageFetched = !!this.customers.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getPaymentActivity({
        period: this.period,
      });
    }
  },
  methods: {
    updateTracker(update) {
      let formattedUpdate = formatIndividualCustomers([{ ...update.item }])[0];
      console.log(formattedUpdate);
      this.pageData.splice(update.index, 1, formattedUpdate);
    },
    getPaymentActivity({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      let payload = {
        type: "individual",
        page: this.page,
        start_date,
        end_date,
      };

      if (this.gardenerId) {
        payload.gardener_id = this.gardenerId;
      }

      this.$store
        .dispatch(actions.GET_GARDENERS_PAYMENT_TRACKER, payload)
        .then(() => {
          this.setPageData();
          this.period = period;
          this.custom.from = from;
          this.custom.to = to;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      payment
        .search(query)
        .then((response) => {
          this.pageData = formatIndividualCustomers(
            response.data.data.customers,
          );
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
      });
      let constParams = `customer_type=individual&page=${this.page}`;

      if (params.start_date && params.end_date) {
        start_date = params.start_date;
        end_date = params.end_date;
        delete params.start_date;
        delete params.end_date;
        constParams += `&start_date=${start_date}&end_date=${end_date}`;
      }

      let stringedParams;
      if (Object.keys(params).length) {
        stringedParams = `${constParams}&${this.sortObjectAsParams(params)}`;
      } else {
        stringedParams = constParams;
      }
      payment
        .filter(stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = formatIndividualCustomers(response.data.data);
          this.pagination.from = response.data.meta.from;
          this.pagination.to = response.data.meta.to;
          this.pagination.total = response.data.meta.total;
          this.pagination.perPage = response.data.meta.per_page;
          this.showPagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = formatIndividualCustomers(
        this.customers.pages[this.page].data,
      );
      this.pagination.from = this.customers.pages[this.page].from;
      this.pagination.to = this.customers.pages[this.page].to;
      this.pagination.total = this.customers.total;
      this.pagination.perPage = this.customers.per_page;
      this.showPagination = true;
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      this.page = 1;
      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.setPageData();
      }
    },
    clearFilters() {
      this.page = 1;
      this.setPageData();
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
