<template>
  <div>
    <el-dialog
      :title="`Export Payment Data - ${formatText(type)}`"
      :visible.sync="setShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        :format="form.format"
        :exporting.sync="exporting"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'name' || key === 'email'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportCustomers"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import payment from "@/requests/gardeners/payment-tracker";

export default {
  name: "PaymentTrackerExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        email: "Email",
        status: "Status",
        gardener: "Gardener",
        services: "Services",
        expected_amount: "Expected Amount",
        billing_date: "Billing Date",
        payment_status: "Payment Status",
        reason: "Reason",
        amount_paid: "Amount Paid",
        payment_date: "Payment Date",
        conversion: "Conversion",
        feedback: "Feedback",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "email"],
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.type === "corporate") {
          this.fields.employees = "Employees";
          delete this.fields.gardener;
        }
      }
    },
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["name", "email"];
      this.form.format = "";
      this.setShow = false;
      this.exporting = false;
    },
    exportCustomers() {
      if (this.dataToExport.length) {
        this.formatExportData(this.dataToExport);
      }
    },
    getExportData() {
      this.loading = true;
      payment
        .export()
        .then((response) => {
          this.formatExportData(response.data.data.customers);
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },

    formatExportData(data) {
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Payment Tracker - ${this.formatText(
        this.type
      )} Customers \nExported at - ${date}`;
      let headers = {
        sn: "S/N",
      };
      let customerData = [];
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
      customerData = data.map((customer, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "name":
              export_object[key] = this.formatName(customer.name);
              break;
            case "email":
              export_object[key] = customer.email.toLowerCase();
              break;
            case "status":
              export_object[key] = this.formatText(customer.status);
              break;
            case "gardener":
              export_object[key] = customer.gardener;
              break;
            case "services":
              export_object[key] = customer.services.toString();
              break;
            case "expected_amount":
              export_object[key] = customer.subscription_amount;
              break;
            case "billing_date":
              export_object[key] = this.formatDate(
                customer.billing_date,
                "dddd do, m, y"
              );
              break;
            case "payment_status":
              export_object[key] = customer.payment_status;
              break;
            case "reason":
              export_object[key] = customer.reason;
              break;
            case "amount_paid":
              export_object[key] = customer.amount_paid;
              break;
            case "payment_date":
              export_object[key] = this.formatDate(
                customer.payment_date,
                "dddd do, m, y"
              );
              break;
            case "conversion":
              export_object[key] = customer.conversion.toString();
              break;
            case "employees":
              export_object[key] = customer.number_of_employees;
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: customerData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
