<template>
  <div>
    <eden-table-actions :title="title" :show-search="false" @search="search">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getPaymentActivity"
        />
        <payment-tracker-filter
          :class="'mx-10'"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          :disabled="!allowExport"
          @click="showCustomersExport = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData">
        <el-table-column width="220">
          <template slot="header">
            <span>Name </span>
          </template>
          <template v-slot="scope">
            <span class="text-primary"> {{ formatName(scope.row.name) }}</span>
            <span class="is-block font-xsm">{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <span> Status </span>
          </template>
          <template v-slot="scope">
            <el-tag :type="setType(scope.row.status)">{{
              formatText(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span> Services </span>
          </template>
          <template v-slot="scope">
            <eden-services-list
              v-if="scope.row.services"
              :services="scope.row.services"
            />
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span> Expected Amount </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.subscription_amount) }}</p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span> Billing Date </span>
          </template>
          <template v-slot="scope">
            <span>{{
              scope.row.billing_date
                ? formatDate(scope.row.billing_date, "m do, y")
                : `-`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <span> Employees </span>
          </template>
          <template v-slot="scope">
            <p class="text-grey-primary">
              {{
                scope.row.employees_id != null
                  ? scope.row.employees_id.length
                  : 0
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span>Payment Status </span>
          </template>
          <template v-slot="scope">
            <el-tag :type="setType(scope.row.payment_status)">
              {{ formatText(scope.row.payment_status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span> Reason </span>
          </template>
          <template v-slot="scope">
            <span>{{ formatText(scope.row.reason) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <span> Amount Paid </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.amount_paid) }}</p>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span> Payment Date </span>
          </template>
          <template v-slot="scope">
            <span>{{
              scope.row.payment_date
                ? formatDate(scope.row.payment_date, "m do, y")
                : `-`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span>Conversion </span>
          </template>
          <template v-slot="scope">
            <div
              v-if="scope.row.conversion.length"
              class="is-flex is-align-center"
            >
              <el-tag
                v-for="(type, index) in scope.row.conversion"
                :key="index"
                :type="setType(type)"
              >
                {{ formatText(type) }}
              </el-tag>
            </div>
            <el-tag v-else type="info"> Not Applicable </el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <payment-tracker-actions
              :customer="scope.row"
              :account="'corporate'"
              @update="getPaymentActivity({ period: period })"
            />
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="pageData.length && showPagination"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :page-size="pagination.perPage"
        :current-page.sync="page"
      />
    </template>
    <payment-tracker-export
      :show.sync="showCustomersExport"
      :type="'corporate'"
      :data-to-export="pageData"
    />
  </div>
</template>

<script>
import PaymentTrackerFilter from "@/components/Gardeners/PaymentTracker/PaymentTrackerFilter";
import PaymentTrackerExport from "@/components/Gardeners/PaymentTracker/PaymentTrackerExport";
import PaymentTrackerActions from "@/components/Gardeners/PaymentTracker/Actions/PaymentTrackerActions";

import payment from "@/requests/gardeners/payment-tracker";
import corporate from "@/requests/customers/corporate";

import { formatCorporateCustomers } from "../format-data";
import * as actions from "@/store/action-types";

export default {
  name: "CustomersCorporateTracker",
  props: {
    gardenerId: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    PaymentTrackerFilter,
    PaymentTrackerExport,
    PaymentTrackerActions,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      period: "thisweek",
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
      },
      showPagination: true,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: {
        from: "",
        to: "",
        total: "",
        perPage: "",
      },
      showCustomersExport: false,
    };
  },
  computed: {
    customers() {
      return this.$store.getters.gardeners_payment_tracker.corporate;
    },
    title() {
      const total = this.pagination.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Corporate Customers`;
    },
    allowExport() {
      return this.pageData.length && !this.loading;
    },
  },
  watch: {
    page() {
      if (!this.filterParams.status) {
        const pageFetched = !!this.customers.pages[this.page];
        if (pageFetched) {
          this.setPageData();
        } else {
          this.getCustomers();
        }
      } else {
        this.filter(this.filterParams);
      }
    },
  },
  created() {
    const pageFetched = !!this.customers.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getPaymentActivity({ period: this.period });
    }
  },
  methods: {
    getPaymentActivity({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      let payload = {
        type: "corporate",
        page: this.page,
        start_date,
        end_date,
      };

      if (this.gardenerId) {
        payload.gardener_id = this.gardenerId;
      }
      this.$store
        .dispatch(actions.GET_GARDENERS_PAYMENT_TRACKER, payload)
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
      });
      let constParams = `customer_type=corporate&page=${this.page}`;

      if (params.start_date && params.end_date) {
        start_date = params.start_date;
        end_date = params.end_date;
        delete params.start_date;
        delete params.end_date;
        constParams += `&start_date=${start_date}&end_date=${end_date}`;
      }

      let stringedParams;
      if (Object.keys(params).length) {
        stringedParams = `${constParams}&${this.sortObjectAsParams(params)}`;
      } else {
        stringedParams = constParams;
      }
      payment
        .filter(stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          console.log(response.data);
          this.pageData = formatCorporateCustomers(response.data.data);
          this.pagination.from = response.data.meta.from;
          this.pagination.to = response.data.meta.to;
          this.pagination.total = response.data.meta.total;
          this.pagination.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      corporate
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = formatCorporateCustomers(
        this.customers.pages[this.page]?.data,
      );
      this.pagination.from = this.customers.pages[this.page].from;
      this.pagination.to = this.customers.pages[this.page].to;
      this.pagination.total = this.customers.total;
      this.pagination.perPage = this.customers.per_page;
      this.showPagination = true;
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      this.page = 1;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.setPageData();
      }
    },
    clearFilters() {
      this.page = 1;
      this.setPageData();
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>
