<template>
  <div class="page-stat">
    <div class="page-stat--body">
      <div class="page-stat--card border-right">
        <div v-if="loading" class="hyphen">-</div>
        <div class="stat" v-else>
          <div class="stat-figure">
            <span class="regular">{{ formatPrice(receivedAmount) }} </span>
            <span class="font-sm text-grey-tertiary"
              >received out of
              <span class="text-bold">{{ formatPrice(totalAmount) }}</span>
              expected</span
            >
          </div>
          <el-progress
            class="mt-10 ml-0 style-bar"
            :percentage="percent(receivedAmount, totalAmount)"
            :color="color"
            :stroke-width="8"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
      <div class="page-stat--card">
        <div v-if="loading" class="hyphen">-</div>
        <div class="stat" v-else>
          <div class="stat-figure">
            <span class="regular">{{ paidCustomers }} </span>
            <span class="font-sm text-grey-tertiary">
              customers paid out of
              <span class="text-bold">{{ totalCustomers }}</span> to pay</span
            >
          </div>
          <el-progress
            class="mt-10 ml-0 style-bar"
            :percentage="percent(paidCustomers, totalCustomers)"
            :color="color"
            :stroke-width="8"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentTrackerStat",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    receivedAmount: {
      type: [String, Number],
      default: 0,
    },
    totalAmount: {
      type: [String, Number],
      default: 0,
    },
    paidCustomers: {
      type: [String, Number],
      default: 0,
    },
    totalCustomers: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      color: "#14B757",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.hyphen {
  font-size: 48px;
  text-align: center;
}
.page-stat {
  border: 1px solid #f0f4f2;
  border-radius: 16px;
  margin-top: 26px;
  margin-bottom: 40px;
  &--body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .border-right {
    border-right: 1px solid #f0f4f2;
  }
  &--card {
    padding: 30px 10px;
    transition: all 0.3s ease-in-out;
    width: 50%;
    margin-top: 16px;
    margin-bottom: 16px;
    .stat {
      margin-left: 50px;
      .style-bar {
        width: 80%;
      }
      &-figure {
        display: flex;
        align-items: center;
        color: var(--eden-grey-primary);
        margin-bottom: 7px;
        position: relative;
        .regular {
          font-family: "PT Serif", serif;
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
          color: var(--eden-grey-primary);
          display: flex;
          align-items: flex-end;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
