<template>
  <div>
    <el-dropdown @command="command" class="more">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <div class="payment-actions">
          <template v-for="(action, i) in actions">
            <el-dropdown-item
              v-if="allowAccessFor(action.access)"
              :key="i"
              :icon="action.icon"
              :command="{
                action: action.name,
                component: action.component,
              }"
            >
              {{ action.label }}</el-dropdown-item
            >
          </template>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :customer="customer"
      :account="account"
      :action="customer.feedback ? 'edit' : 'add'"
      @update="updateTracker"
      @success="$emit('update')"
    />
  </div>
</template>

<script>
export default {
  name: "TrackerActions",
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    account: {
      type: String,
      default: "individual",
    },
    index:{
      type:Number,
      default: 0
    }
  },
  components: {
    PaymentInformation: () => import("./PaymentInformation"),
    PaymentFeedbackForm: () => import("./PaymentFeedbackForm"),
    PaymentReasonForm: () => import("./PaymentReasonForm"),
    PaymentConversionStatus: () => import("./PaymentConversionStatus"),
    PaymentStatus: () => import("./PaymentStatus"),
    CustomerStatus: () => import("./CustomerStatus"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
    };
  },
  computed: {
    actions() {
      return [
        {
          access: ["admin", "growth", "gardener", "gardener_pro"],
          icon: "eden-icon-user",
          label: "Expand details",
          name: "payment-information",
          component: "payment-information",
        },
        {
          access: ["admin", "superadmin", "gardener", "gardener_pro"],
          icon: "el-icon-chat-line-square",
          label: `${
            this.customer.feedback ? "Edit" : "Add"
          } additional feedback`,
          name: "payment-feedback-form",
          component: "payment-feedback-form",
        },
        {
          access: ["admin", "superadmin", "gardener", "gardener_pro"],
          icon: "eden-icon-edit",
          label: "Change reason",
          name: "payment-reason-form",
          component: "payment-reason-form",
        },
        {
          access: ["admin", "superadmin", "gardener", "gardener_pro"],
          icon: "el-icon-open",
          label: "Change conversion status",
          name: "conversion-status-change",
          component: "payment-conversion-status",
        },
        {
          access: ["admin", "superadmin", "gardener"],
          icon: "eden-icon-user",
          label: "Change customer status",
          name: "customer-status-change",
          component: "customer-status",
        },
        {
          access: ["admin", "superadmin", "gardener"],
          icon: "eden-icon-edit",
          label: "Change payment status",
          name: "payment-status-change",
          component: "payment-status",
        },
      ];
    },
  },
  methods: {
    updateTracker(update){
      this.$emit('updateTracker',{index:this.index, item:update})
    },    
    command(command) {
      this.action.command = command.action;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.payment-actions {
  p {
    padding: 0 20px;
    margin: 10px 0;
    color: var(--eden-grey-primary);
    text-transform: uppercase;
    font-size: 0.825rem;
    font-weight: 600;
  }
}
</style>
