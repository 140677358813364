<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "PaymentTrackerFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      properties: {
        customer_status: {
          key: "customer_status",
          label: "Customer Status",
          type: "list",
          options: [
            {
              label: "Subscribers",
              value: "subscriber",
            },
            {
              label: "Paused",
              value: "paused",
            },
            {
              label: "Churned",
              value: "churned",
            },
            {
              label: "Prospects",
              value: "prospect",
            },
          ],
          value: [],
          searchable: false,
        },
        payment_status: {
          key: "payment_status",
          label: "Payment Status",
          type: "list",
          options: [
            {
              label: "Renewed",
              value: "renewed",
            },
            {
              label: "New-Subscription",
              value: "new-subscription",
            },
            {
              label: "Late-Renewal",
              value: "late-renewal",
            },
          ],
          value: [],
          searchable: false,
        },
        conversion_type: {
          key: "conversion_type",
          label: "Conversion Type",
          type: "list",
          options: [
            {
              label: "Cross-sell",
              value: "cross-sell",
            },
            {
              label: "Downsell",
              value: "downsell",
            },
            {
              label: "Upsell",
              value: "upsell",
            },
            {
              label: "Resurrection",
              value: "resurrection",
            },
          ],
          value: [],
        },
        service: {
          key: "service_type",
          label: "Services",
          type: "list",
          options: [],
          value: [],
        },
        gardener: {
          key: "gardener_id",
          label: "Gardener",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        next_charge: {
          key: ["start_date", "end_date"],
          label: "Next Charge Date",
          type: "daterange",
          value: [],
        },
        payment_date: {
          key: ["payment_date_start", "payment_date_end"],
          label: "Payment Date",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  computed: {
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getGardeners();
      this.setServices();
    },
    getGardeners() {
      if (this.properties.gardener.options.length) {
        this.loading = false;
        return;
      }

      this.gardeners.length
        ? this.setGardenerOptions()
        : this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
            this.setGardenerOptions();
          });
    },
    setGardenerOptions() {
      this.properties.gardener.options = this.gardeners.map((gardener) => {
        return {
          label: this.formatName(gardener.name),
          value: gardener.id,
        };
      });
      this.loading = false;
    },
    setServices() {
      this.properties.service.options = this.services.map((service) => {
        return {
          label: this.formatText(service.service_name),
          value: service.service_name.toLowerCase(),
        };
      });
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
