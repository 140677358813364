export const formatIndividualCustomers = (data) => {
  return data.map((customer) => {
    return {
      tracker_id: customer.id,
      name: customer.customer_name.toLowerCase(),
      email: customer.customer_email.toLowerCase(),
      status: customer.customer_status,
      gardener: customer.gardener && customer.gardener.name,
      services: customer.services,
      plans:
        customer.services_count &&
        Object.keys(customer.services_count).reduce((services, service) => {
          return {
            ...services,
            [service]: {
              item: customer.services_count[service].services_item,
              frequency: customer.services_count[service].services_frequency,
              qty: customer.services_count[service].services_qty,
              service_day:
                customer.services_count[service].services_service_day,
              item_areas: {},
            },
          };
        }, {}),
      subscription_amount: customer.expected_payment_amount ?? 0,
      billing_date: customer.next_billing_date,
      payment_status: customer.payment_status,
      reason: customer.reason,
      amount_paid: customer.amount_paid,
      payment_date: customer.payment_date,
      conversion: customer.conversion_type,
      feedback: customer.additional_feedback ?? "",
    };
  });
};

export const formatCorporateCustomers = (data) => {
  return data.map((customer) => {
    return {
      tracker_id: customer.id,
      name: customer.customer_name.toLowerCase(),
      email: customer.customer_email.toLowerCase(),
      status: customer.customer_status,
      services: customer.services,
      plans:
        customer.services_count &&
        Object.keys(customer.services_count).reduce((services, service) => {
          return {
            ...services,
            [service]: {
              item: customer.services_count[service].services_item,
              frequency: customer.services_count[service].services_frequency,
              qty: customer.services_count[service].services_qty,
              service_day:
                customer.services_count[service].services_service_day,
              item_areas: {},
            },
          };
        }, {}),
      number_of_employees: customer.number_of_employees ?? "-",
      employees_id: customer.employees_id,
      subscription_amount: customer.expected_payment_amount ?? 0,
      billing_date: customer.next_billing_date,
      payment_status: customer.payment_status,
      reason: customer.reason,
      amount_paid: customer.amount_paid,
      payment_date: customer.payment_date,
      conversion: customer.conversion_type,
      feedback: customer.additional_feedback ?? "",
    };
  });
};
